<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t( translationPath + 'title_income_categories') }}</h1>
      </b-row>
      <account-plan-submenu active="incomes"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-incomes-header></account-plan-incomes-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs" @activate-tab="onTabActivate">
            <IncomeMenu>
              <template slot="account-plan-incomes-overview">
                <account-plan-overview-table
                  :field="fieldValues"
                  :data="tableData"
                  :rowClass="trClass"
                  accountType="income"
                  :busy="busy"
                  @delete-item="onDeleteAccount"
                  @delete-group="deleteAccountGroupModal"
                  @account-created="onAccountCreated"
                  edit-base-link="/incomes-expenses/accounts/"
                />
              </template>
            </IncomeMenu>
          </b-tabs>
        </b-card>

      </div><!-- END: wrapper_white_with_border -->
    </div><!-- END: main-content-wrapper -->

    <delete-networth-account-modal ref="deleteModalNetworth"></delete-networth-account-modal>

    <delete-account-modal
      ref="deleteModal"
      :itemName="deleteAccountName"
      :itemType="deleteAccountType"
      :moveTransactions="onDeleteMoveTransactions"
      :iDestroyAccountId="iDestroyAccountId"
      @on-delete-confirm="deleteAccount"
      @hide="resetModal"
    ></delete-account-modal>

    <delete-account-group-modal
      ref="deleteAccountGroupModal"
      :itemName="deleteAccountName"
      @on-delete-confirm="deleteAccountGroup"
      @hide="resetModal"
    ></delete-account-group-modal>

    <account-group-prevent-delete
      ref="deleteAccountGroupPreventModal"
      :itemName="deleteAccountName"
      @hide="resetModal"
    />

    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>
  </b-container>
</template>

<script>
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AccountPlanActionsMixin from './AccountPlanActionsMixin'
import IncomeMenu from './submenu/IncomeMenu'
import AccountPlanIncomesHeader from './headers/AccountPlanIncomesHeader'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'AccountPlanIncomesOverview',
  mixins: [AccountPlanTabMixin, AccountPlanActionsMixin],
  components: { IncomeMenu, AccountPlanIncomesHeader },
  data () {
    return {
      accountsFilter: '',
      tableData: []
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'title', label: this.$t(this.translationPath + 'table_title_incomes'), sortable: false },
        // { key: 'default_active_percentage', lable: this.$t('common.active_percentage'), sortable: false, class: 'text-left text-md-right' },
        { key: 'amount', label: this.$t(this.translationPath + 'overview.category_total', { year: new Date().getFullYear() }), class: 'text-right', formatter: this.amountFormatter },
        { key: 'actions', label: this.$t(this.translationPath + 'action'), class: 'text-right' }
      ]
    }
  },
  methods: {
    amountFormatter (value) {
      return formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0)
    },
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }

      this.busy = true
      Promise.all([this.loadAccountGroupsWithData('income', '&show_inactive=1')]).then(() => {
        this.tableData = this.createTableDataWithEmptyRows(this.accountsGrouped.income)
        this.busy = false
      })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
</style>
